import request from "@/request";

// 留言消息
class MsgApi {
    /**
    * @Description:保存留言
    */
    save(data){
        return request.post({
            url:"/mobile/msg/save",
            data
        })
    }
    /**
    * @Description:分页查询留言
    */
    pageQuery(params){
        return request.post({
            url:"/mobile/msg/pageQuery",
            params
        })
    }
    /**
    * @Description:获取留言详情
    */
    get(id){
        return request.get({
            url:"/mobile/msg/get",
            params:{id}
        })
    }
    /**
    * @Description:新建留言明细
    */
    saveDetail(data){
        return request.post({
            url:"/mobile/msg/saveDetail",
            data
        })
    }
    /**
    * @Description:获取未读消息数量
    */
    getNum(){
        return request.get({
            url:"/mobile/msg/getNum"
        })
    }
}

export default new MsgApi()