import request from "@/request";
import storageUtils from "@/utils/storageUtils";

/**
 * @Description:购物车相关
 */
class CartApi {
    /**
     * @Description:分页查询当前用户购物车中的商品数据
     */
    pageQuery(params, data) {
        return request.post({
            url: "/mobile/cart/pageQuery",
            data: {
                ...data,
                businessId: storageUtils.getItem('businessId')
            },
            params
        })
    }

    /**
     * @Description:设置购物车中指定商品的数量
     */
    change(data) {
        return request.post({
            url: '/mobile/cart/change',
            data
        })
    }

    /**
     * @Description:删除购物车中指定商品
     */
    delete(ids) {
        return request.post({
            url: '/mobile/cart/delete',
            data: {
                ids: ids
            }
        })
    }

    /**
     * @Description:加入购物车
     */
    save(data) {
        return request.post({
            url: '/mobile/cart/save',
            data
        })
    }

    /**
     * @Description:清空当前用户的购物车
     */
    clear(){
        return request.post({
            url:"/mobile/cart/clear"
        })
    }
    /**
    * @Description:不分页查询当前用户购物车中的商品数据
    */
    query(){
        return request.post({
            url:"/mobile/cart/query"
        })
    }
}

export default new CartApi()