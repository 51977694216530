<template>
  <div id="app">
    <router-view v-if="business"/>
    <l-check-password/>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {debounce} from "lodash";
import LCheckPassword from "@/components/l-checkPassword/l-checkPassword.vue";

export default {
  components: {LCheckPassword},
  async created() {
    await this.getBusiness();
    this.getMsgNum();
    this.get();
    this.queryCart();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('base', ['business'])
  },
  methods: {
    ...mapMutations('base', ['changeScrollTop']),
    ...mapActions('base', ['getBusiness']),
    ...mapActions('user', ['get', 'queryCart', 'getMsgNum']),
    handleScroll: debounce(function () {
      this.changeScrollTop(window.scrollY);
    }, 0)
  },
}

</script>
<style lang="scss">
</style>
