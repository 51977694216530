import request from "@/request";
import qs from "querystring"
import storageUtils from "@/utils/storageUtils";

// 订单
class OrderApi {
	/**
	 * @Description:获取发货地到收货地的运费
	 */
	getDeliveryFee(data){
		return request.post({
			url: "/index/mobile/order/getDeliveryFee",
			data
		})
	}
	
	/**
	 * @Description:下单
	 */
	save(data){
		return request.post({
			url: "/mobile/order/save",
			data
		})
	}
	
	/**
	 * @Description:获取订单详情
	 */
	get(id){
		return request.get({
			url: '/mobile/order/get',
			params: { id }
		})
	}
	
	/**
	 * @Description:分页查询订单
	 * @param {Object} params 分页
	 * @param {number} params.start 分页开始位置
	 * @param {number} params.limit 分页数量
	 * @param {Object} data 查询条件
	 */
	pageQuery(params, data){
		return request.post({
			url: "/mobile/order/pageQuery",
			params,
			data
		})
	}
	
	/**
	 * @Description:取下订单
	 */
	cancel(data){
		return request.post({
			url: '/mobile/order/cancel',
			data
		})
	}
	
	/**
	 * @Description:删除订单
	 */
	removeById(id){
		return request.get({
			url: "/mobile/order/removeById",
			params: { id }
		})
	}
	
	/**
	 * @Description:修改收货地信息
	 */
	changeOrderAddress(data){
		return request.post({
			url: "/mobile/order/changeOrderAddress",
			data
		})
	}
	
	/**
	 * @Description:确认收货
	 * @param {stirng} id 订单id
	 */
	deliveryGoods(id){
		return request.get({
			url: "/mobile/order/deliveryGoods",
			params: { id }
		})
	}
	
	/**
	 * @Description:分页查询进货订单
	 */
	pageQueryRestock(params, data){
		return request.post({
			url: "/mobile/order/pageQueryRestock",
			data,
			params
		})
	}
	
	/**
	 * @Description:校验进货密码
	 */
	judyPassword(password){
		return request.get({
			url: "/mobile/order/judyPassword",
			params: { password }
		})
	}
	
	/**
	 * @Description:导出订单
	 */
	exportOrder(params){
		params = {
			...params,
			Token: storageUtils.getItem('token')
		}
		let url = `${process.env.VUE_APP_URL}/mobile/order/exportOrderWeb?${qs.stringify(params)}`;
		window.open(url)
	}
	
	/** 订单删除商品 */
	removeGoodsById(id){
		return request.get({
			url: '/mobile/order/removeGoodsById',
			params: { id }
		})
	}
	
	/** 修改备注 */
	changeDescription(data){
		return request.post({
			url: '/mobile/order/changeDescription',
			data
		})
	}
	
	/** 添加订单商品 */
	addOrderGoods(data){
		return request.post({
			url: '/mobile/order/addOrderGoods',
			data,
		})
	}
	
	/** 修改商品数量 */
	changeQuantity(data){
		return request.post({
			url: '/mobile/order/changeQuantity',
			data
		})
	}
	
	/** 修改商品价格 */
	changeMoney(data){
		return request.post({
			url: '/mobile/order/changeMoney',
			data
		})
	}
}

export default new OrderApi()