import VipApi from "@/api/vipApi";
import storageUtils from "@/utils/storageUtils";
import CartApi from "@/api/cartApi";
import MsgApi from "@/api/MsgApi";

const user = {
	namespaced: true,
	state: {
		user: null,
		cartData: [],//购物车商品信息
		cartTotal: 0,//购物车总数
		orderGoodsList: [],//订单商品
		msgNum:0,//未读消息数量
	},
	mutations: {
		// 修改用户信息
		changeUser(state, user){
			storageUtils.setItem('user', user)
			state.user = user;
		},
		// 修改购物车
		changeCart(state, cart = []){
			storageUtils.setItem('cart', cart)
			state.cartTotal = cart.length;
			state.cartData = cart;
		},
		// 重置掉所有的用户数据
		resetData(state){
			storageUtils.removeItem('user');
			storageUtils.removeItem('cart');
			state.user = null;
			state.cartTotal = 0;
			state.cartData = []
		},
		//  修改订单商品
		changeOrderGoodsList(state, orderGoodsList){
			orderGoodsList = orderGoodsList || []
			storageUtils.setItem('orderGoodsList', orderGoodsList);
			state.orderGoodsList = orderGoodsList;
		},
		// 修改未读消息数量
		changeMsgNum(state,num){
			storageUtils.setItem('num', num)
			state.msgNum = num;
		}
	},
	getters: {},
	actions: {
		// 获取用户信息
		async get(context){
			const token= storageUtils.getItem('token');
			if(token){
				const res = await VipApi.get();
				context.commit('changeUser', res)
				return res
			}
		},
		// 获取购物车
		async queryCart(context){
			const token= storageUtils.getItem('token');
			if(token){
				const res = await CartApi.query();
				context.commit('changeCart', res);
				return res
			}
		},
		// 获取未读消息数量
		async getMsgNum(context){
			const token= storageUtils.getItem('token');
			if(token){
				const res=await MsgApi.getNum();
				context.commit('changeMsgNum', res)
			}
		}
	},
};
export default user;
