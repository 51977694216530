<script>
export default {
  name: "l-dialog",
  data(){
    return {
      loading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    confirmFn: {
      type: Function,
    },
    // 是否在弹出层中在弹出
    appendToBody: {
      type: Boolean,
      default: false
    },
    /** 调整 宽度 */
    width: {
      type: String,
      default: '50%'
    }
  },
  methods: {
    handleClose(){
      this.$emit('close', false);
      this.$emit('update:visible', false);
    },
    async handleSubmit(){
      this.loading = true;
      try {
        this.confirmFn && await this.confirmFn();
        this.loading = false
        this.handleClose()
      }
      catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <el-dialog :title="title" :visible="visible" @close="handleClose" :append-to-body="appendToBody" :width="width">
    <slot></slot>
    <template #footer>
      <slot name="footer">
        <div class="dialog__footer">
          <el-button type="info" size="mini" @click.native="handleClose">取消</el-button>
          <!--         <el-button type="primary" size="mini" :loading="loading" @click="handleSubmit">保存</el-button>-->
          <div class="dialog__footer__enter">
            <el-input size="mini" value="保存" type="submit" :loading="loading" @click.native="handleSubmit()">保存
            </el-input>
          </div>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
::v-deep {
  .el-dialog__header {
    padding: 0 20px;
    background: $bg;
    position: relative;
  }

  .el-dialog__title {
    font-size: $font-size-base;
    color: $gray-500;
    line-height: 40px;
  }

  .el-dialog__headerbtn {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: $font-size-md;
  }

  .el-dialog__footer {
    padding: 20px;

    .el-button {
      width: 100px;
    }
  }
}

.dialog__footer {
  display: flex;
  justify-content: right;

  .dialog__footer__enter {
    margin-left: 20px;
    width: 100px;

    ::v-deep {
      .el-input {
        .el-input__inner {
          background: #d55659;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>